import React, { useState, useEffect } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import "./App.css";

function App() {
  const [image, setImage] = useState(
    "https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
  );

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const download = (editedImageObject, designState) => {
    var ImageBase64 = editedImageObject.imageBase64;
    var a = document.createElement("a"); //Create <a>
    a.href = ImageBase64; //Image Base64 Goes here
    a.download = editedImageObject.name + editedImageObject.extension; //File name Here
    a.click(); //Downloaded file
  };

  return (
    <div className="container">
      <div className="custom-input-file">
        <input type="file" onChange={onImageChange} className="input-file" />
          Buscar imagen
      </div>

      <FilerobotImageEditor
        source={image}
        onSave={(editedImageObject, designState) =>
          download(editedImageObject, designState)
        }
        annotationsCommon={{
          fill: "#ff0000",
        }}
        Text={{ text: "texto nuevo..." }}
        Rotate={{ angle: 90, componentType: "slider" }}
        tabsIds={[
          TABS.ADJUST,
          TABS.FINETUNE,
          TABS.ANNOTATE,
          TABS.WATERMARK,
          TABS.FILTERS,
          TABS.RESIZE,
        ]} // or {['Adjust', 'Annotate', 'Watermark']}
        defaultTabId={TABS.ANNOTATE} // or 'Annotate'
        defaultToolId={TOOLS.TEXT} // or 'Text'
      />
    </div>
  );
}

export default App;
